import React from "react"

import { Link } from "gatsby"

import "../MobileRoadmap.scss"

export default function MobileAccents({ accents }) {
  return (
    <div className="mobile">
      <div className="mobile-accents">
        <div className="container">
          <div className="row">
            {accents.map((group, idx) => (
              <ul
                key={idx}
                className="mobile-accents-list col-lg-6 col-md-6 col-sm-12 col-xs-12"
              >
                {group.map(accent => (
                  <li key={accent.id} className="mobile-accents-list_item">
                    <span className="icon-bg-gradient">
                      <img src={accent.img} alt="V" />
                    </span>
                    {
                      <p>
                        {accent.link ? (
                          <Link
                            className="navigation-link"
                            to={accent.link}
                            style={{ padding: 0, lineHeight: "3.4rem" }}
                          >
                            <strong>Always</strong> staying in touch
                          </Link>
                        ) : null}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: accent.description,
                          }}
                        />
                      </p>
                    }
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
