import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import HeaderSection from "../../components/Services/ServiceHeader"
import MobileAccents from "../../components/SingleService/Mobile/MobileAccents"
import ServiceDescription from "../../components/SingleService/Common/ServiceDescription"
import LatestProjects from "../../components/UiUxDesign/uiUxLatestProject"
import WorkTogetherSection from "../../components/Services/WorkTogetherSection"
import dataForMobile from "../../information/Services/dataForMobile"
import { mainUrl } from "../../js/config"
import "../../components/SingleService/Mobile/MobileRoadmap.scss"

export default function Mobile() {
  const { header, articles, accents } = dataForMobile
  return (
    <section>
      <SEO
        title="Mobile App Development Services"
        description="Looking for mobile application development services? A unique mobile application developed by the OSsystem team will help you promote your brand."
        canonical={`${mainUrl}/services/mobile/`}
      />
      <Layout showFormPoint={50}>
        <HeaderSection title={header.title} BG={header.background} />
        <ServiceDescription articles={articles} />
        <MobileAccents accents={accents} />
        <LatestProjects />
        <WorkTogetherSection />
      </Layout>
    </section>
  )
}
