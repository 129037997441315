import { MobileBG } from "../../images/Services/Backgrounds"

import {
  Balance,
  InTouch,
  Iteration,
  MobileScrum,
  Technical,
} from "../../images/Services/Mobile"

const dataForMobile = {
  service: "mobile",
  header: {
    title: "Mobile Apps Development",
    background: MobileBG,
  },
  articles: {
    image: MobileScrum,
    text: [
      "<strong>Developing</strong> a bespoke mobile application for your business can give you a huge competitive advantage leading to faster customer onboarding and promoting your brand to a wider audience virtually covering the entire globe.<br><br>Whichever platform you choose: <strong>Android, iOS or Windows</strong> — our mobile app developers can develop a unique standalone mobile application for your brand or a mobile application to complement an existing web application.",
    ],
  },
  accents: [
    [
      {
        id: 1,
        img: Balance,
        description:
          "<span><strong>A balanced</strong> approach</span> in terms of projected end results and customization",
      },
      {
        id: 2,
        img: Technical,
        description:
          "<span><strong>Technical</strong> expertise</span> keeping up with the latest development trends",
      },
    ],
    [
      {
        id: 3,
        img: InTouch,
        link: "/estimate",
        description: " and supporting you even after commercial deployment",
      },
      {
        id: 4,
        img: Iteration,
        description:
          "<span><strong>Iteration</strong> during</span> the testing phase until we reach what you consider the best",
      },
    ],
  ],
  projects: [
    {
      id: "210",
    },
    {
      id: "230",
    },
  ],
}

export default dataForMobile
